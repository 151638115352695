/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@300;500;700&family=Montserrat:wght@800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Montserrat:wght@800&family=Roboto+Serif:wght@400;500;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&family=Montserrat:wght@800&family=Roboto+Serif:wght@400;500;700&display=swap'); */

*, html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  padding: 0 44px;
  padding-bottom: 100vh;
  font-family: 'Roboto Serif', sans-serif;
  font-size: 11px;
  font-weight: 500;
}
.section {
  margin-bottom: 92px;
}
h2 {
  position: sticky;
  top: 0;
  margin-top: 44px;
  padding-top: 26px;
  padding-bottom: 18px;
  font-size: 14px;
  margin-bottom: 24px;
  box-shadow: 0 1px 0 0 rgba(17, 55, 84, 0.1);
  background-color: #fff;
  z-index: 2;
}
#contents h2 {
  margin-top: 0;
}
h3 {
  margin-bottom: 12px;
  font-family: 'Montserrat', sans-serif;
  font-size: 8px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
}
a {
  display: inline-block;
  color: rgb(0, 146, 208);
  text-decoration: none;
}
a:hover { text-decoration: underline; }
a:visited:not(#contents a):not(h2 a) {
  color: rgb(174, 58, 223);
}

h2 a,
h2 a:hover {
  color: #000;
  text-decoration: none;
}

p {
  max-width: 1040px;
}
p, a {
  margin-bottom: 4px;
  line-height: 1.4;
}
strong,
b { font-weight: 700 }

.intro {
  max-width: 700px;
  margin-bottom: 44px;
  padding-top: 12px;
}
.intro p {
  color: rgba(0,0,0,0.84);
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  /* opacity: 0.8; */
}
.intro p:not(:last-child) {
  margin-bottom: 16px;
}

ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
li {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  list-style:none;
}
li::before {
  content: '';
  position: absolute;
  top: -12px;
  left: -44px;
  width: 0;
  height: 100%;
  background-color: #000;
  transition: width 0.1s ease;
}
li:hover::before { width: 3px; }

.page-container {
  position: relative;
  width: 100%;
}

ul.contents a {
  display: block;
  padding: 3px 0;
  margin: 0;
}
ul.contents p {
  max-width: 620px;
  margin-bottom: 12px;
  line-height: 1.5;
}

ul.stats-container {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
ul.stats-container li::before {
  display: none;
}
ul.stats-container > div {
  display: flex;
  flex-direction: column;
}
ul.stats-container h4 {
  font-weight: 500;
  letter-spacing: 0.5px;
}

a.contents-link {
  position: fixed;
  display: block;
  z-index: 10;
  top: 22px;
  right: 44px;
  width: 18px;
  height: 18px;
  color: transparent;
  background-color: #000;
  opacity: 0.3;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url('./assets/books-light.svg');
  transition: opacity 0.1s ease;
}
a.contents-link:hover {
  opacity: 0.6;
}


table.ab-table {
  margin: 2px 0;
  color: #808080;
  font-family: 'Inter', sans-serif;
  font-size: 9px;
  font-weight: 500;
  text-align: right;
  border-collapse: collapse;
}
table.ab-table td {
  padding: 6px 8px;
  border: solid 1px rgba(0,0,0,0.1);
}

table.ab-table td.green {
  color: #66cc00;
}
table.ab-table td.yellow {
  color: #ff9900;
}
table.ab-table td.red {
  color: #ff6666;
}

table.ab-table tr.heading td,
table.ab-table td.heading {
  color: #fff;
  background: #000;
}
table.ab-table tr.subheading td,
table.ab-table td.subheading {
  color: #b2b2b2 !important;
  background: #333 !important;
}
table.ab-table tr.lightheading td,
table.ab-table td.lightheading {
  background: rgba(0,0,0,0.04);
}
table.ab-table tr.heading td,
table.ab-table td.heading,
table.ab-table tr.subheading td,
table.ab-table td.subheading,
table.ab-table tr.lightheading td,
table.ab-table td.lightheading {
  text-align: left;
}

table.ab-table tr.extrapolated td:not(.subheading) {
  background: rgba(0,0,0,0.04);
}
table.ab-table tr.blank td,
table.ab-table td.blank {
  background: none !important;
}

table.ab-table.simple tr td:last-child {
  text-align: left;
}
